import React, { useEffect } from 'react'
import './AppLoader.css';
import { useRef } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assests/lottieFiles/loader.json';
export default function NewLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div id="appLoader">
            <Lottie options={defaultOptions}
                height={"100%"}
                width={"100%"}
                id="appLoaderFile"
            />
        </div>
    )
}