import React, { Component } from "react";

export default class BlockchainImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <img
        style={{
          height: "auto",
          marginTop: "0px",
        }}
        src={require(`../../assests/images/blockchain.svg`)}
      />
    );
  }
}
