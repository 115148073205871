import React, { useState, useRef, useEffect } from "react";
import "./ProjectCard.css";
import ProjectTechStacks from "../projectTechStacks/ProjectTechStacks";
import ProjectLinks from "../projectLinks/ProjectLinks";
import { Icon } from "@iconify/react";
import { Fade } from "react-reveal";
import Typewriter from "typewriter-effect";

export default function ProjectCard({ project, theme }) {
  console.log(project);
  const [typewriter, setTypewriter] = useState(null);
  const divRef = useRef(null);
  const handleMouseEnter = () => {
    typewriter.start();
  };
  const handleMouseLeave = () => {
    typewriter.stop();
  };
  useEffect(() => {
    const divElement = divRef.current;
    const observer = new MutationObserver(() => {
      if (divElement.scrollHeight > divElement.clientHeight) {
        divElement.scrollTop = divElement.scrollHeight;
      }
    });

    observer.observe(divElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <Fade duration={2000}>
      <div
        className="project-card-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="project-card">
          <div className="project-card-header" style={{ color: theme.text }}>
            <div className="project-card-header-dots-container">
              <span
                className="project-card-header-dot"
                style={{ backgroundColor: "#C84D58" }}
              ></span>
              <span
                className="project-card-header-dot"
                style={{ backgroundColor: "#CBAE53" }}
              ></span>
              <span
                className="project-card-header-dot"
                style={{ backgroundColor: "#70AC4F" }}
              ></span>
            </div>
            {project.name}
          </div>
          <div className="project-info">
            <img
              src={require(`../../assests/images/projects/${project.preview}`)}
              alt={project.name}
              className="project-image"
            />
            <div className="project-description" ref={divRef}>
              <Typewriter
                autoStart={false}
                loop={false}
                onInit={(typewriter) => {
                  typewriter.changeDelay(40).typeString(project.description);
                  setTypewriter(typewriter);
                }}
              />
            </div>
          </div>
          <div className="project-card-footer">
            <ProjectLinks links={project.links} />
            <ProjectTechStacks logos={project.techStacks} />
          </div>
        </div>
      </div>
    </Fade>
  );
}
