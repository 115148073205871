import React, { Component } from "react";
import "./ProjectLinks.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { projectTechStacks } from "../../portfolio.js";

class ProjectLinks extends Component {
  render() {
    return (
      <div className="project-links-container">
        <ul className="project-links-list">
          <OverlayTrigger
            key="Github"
            placement={"top"}
            overlay={
              <Tooltip
                id={`tooltip-top`}
                style={{
                  display: this.props.links.github ? "auto" : "none",
                }}
              >
                <strong>Github</strong>
              </Tooltip>
            }
          >
            <li className="project-links-icon" name="Github">
              <a
                href={this.props.links.github}
                style={{
                  pointerEvents: this.props.links.github ? "auto" : "none",
                }}
                target="_blank"
              >
                <span
                  className="iconify"
                  data-icon="mdi:github"
                  data-inline="false"
                  style={{
                    color: this.props.links.github
                      ? "#3d3d3a"
                      : "rgba(153, 152, 147,0.5)",
                  }}
                ></span>
              </a>
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            key="Web"
            placement={"top"}
            overlay={
              <Tooltip
                id={`tooltip-top`}
                style={{
                  display: this.props.links.live ? "auto" : "none",
                }}
              >
                <strong>Web</strong>
              </Tooltip>
            }
          >
            <li className="project-links-icon" name="Web">
              <a
                href={this.props.links.live}
                style={{
                  pointerEvents: this.props.links.live ? "auto" : "none",
                }}
                target="_blank"
              >
                <span
                  className="iconify"
                  data-icon="mdi:web"
                  data-inline="false"
                  style={{
                    color: this.props.links.live
                      ? "#1572B6"
                      : "rgba(153, 152, 147,0.5)",
                  }}
                ></span>
              </a>
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            key="Youtube"
            placement={"top"}
            overlay={
              <Tooltip
                id={`tooltip-top`}
                style={{
                  display: this.props.links.youtube ? "auto" : "none",
                }}
              >
                <strong>Youtube</strong>
              </Tooltip>
            }
          >
            <li className="project-links-icon" name="Youtube">
              <a
                href={this.props.links.youtube}
                style={{
                  pointerEvents: this.props.links.youtube ? "auto" : "none",
                }}
                target="_blank"
              >
                <span
                  className="iconify"
                  data-icon="entypo-social:youtube"
                  data-inline="false"
                  style={{
                    color: this.props.links.youtube
                      ? "#FF0000"
                      : "rgba(153, 152, 147,0.5)",
                  }}
                ></span>
              </a>
            </li>
          </OverlayTrigger>
        </ul>
      </div>
    );
  }
}

export default ProjectLinks;
