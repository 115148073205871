import React, { Component } from "react";
import "./ProjectTechStacks.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {projectTechStacks} from '../../portfolio.js'

class ProjectTechStacks extends Component {
    render() {
        return (
            <div className="project-tech-stacks-container">
                <ul className="project-tech-stacks-list" >
                    {this.props.logos.map((logoName) => {
                        const logo = projectTechStacks[logoName];
                        return (
                            <OverlayTrigger
                                key={logo.name}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                    <strong>{logo.name}</strong>
                                    </Tooltip>
                                }
                            >
                            <li
                                className="project-tech-stack-icon"
                                name={logo.skillName}
                            >
                                <span
                                    className="iconify"
                                    data-icon={logo.iconifyClass}
                                    data-inline="false"
                                ></span>
                            </li>
                            </OverlayTrigger>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default ProjectTechStacks;
