import React from "react";
import "./ProjectsContainer.css";
import ProjectCard from "../../components/projectCard/ProjectCard";

export default function ProjectsContainer({ projects, theme }) {
    return (
        <div className="projects-container">
            <div className="projects-grid">
                {projects.map((project) => {
                    return <ProjectCard project={project} theme={theme} />;
                })}
            </div>
        </div>
    );
}
