import React, { Component } from "react";
import "./Splash.css";
import { Redirect } from "react-router-dom";
import NewLoader from "../../components/newLoader/newLoader.js";

// function AnimatedSplash(props) {
//   return (
//     <div className="logo_wrapper">
//       <div className="screen" style={{ backgroundColor: props.theme.text }}>
//         <LoaderLogo id="logo" theme={props.theme} />
//       </div>
//     </div>
//   );
// }

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 5500);
  }

  componentWillMount() {
    clearTimeout(this.id);
  }

  render() {
    return this.state.redirect ? (
      <Redirect to="/home" />
    ) : (
      <NewLoader></NewLoader>
    );
  }
}

export default Splash;
