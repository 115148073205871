import React, { Component } from "react";

export default class DSAInstructor extends Component {
    render() {
        const theme = this.props.theme;
        return (
            <img
                style={{
                    height: 'auto',
                    marginTop: '50px',
                }}
                src = {require(`../../assests/images/DSAInstructor.svg`)}
            />
        )
    }
}