/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tejas MIshra",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Tejas Mishra",
    type: "website",
    url: "http://tejasmishra.in/",
  },
};

//Home Page
const greeting = {
  title: "Tejas Mishra",
  logo_name: "TejasMishra",
  nickname: ["Fullstack Developer", "Blockchain Developer", "DSA Instructor"],
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1kEllIpHkqyGSS0m3t5ad1zBdGmDe4vn2/view?usp=sharing",
  portfolio_repository: "https://github.com/tm2k23/",
  githubProfile: "https://github.com/tm2k23/",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/tm2k23/",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Whatsapp",
    link: "https://wa.me/919455786258", //<FontAwesomeIcon icon="fa-brands fa-whatsapp" />
    fontAwesomeIcon: "fa-whatsapp", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tejas2k18/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:tejasmishra2k18@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/fighter_tejas",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  {
    name: "Facebook",
    link: "https://www.facebook.com/code.blooded.tejas/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/tejas_s_mishra/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Frontend Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Crafting captivating websites with HTML, CSS/SASS, JavaScript, Bootstrap, and Material-UI for an engaging user experience.",
        "⚡ Building dynamic web applications using React, Redux, Next.js, and other cutting-edge technologies to create interactive interfaces.",
        "⚡ Leveraging Bootstrap and Material-UI, I deliver visually appealing and user-friendly web solutions that keep up with industry standards.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Backend Developer",
      fileName: "BackendImg",
      skills: [
        "⚡ I power robust server-side applications using Node.js, Express.js, Spring Boot, and Kafka, ensuring optimal performance and scalability.",
        "⚡ Leveraging databases like MongoDB, Firebase, Redis, and MySQL, I efficiently manage and store data for seamless operations.",
        "⚡ With tools like Grafana, Prometheus, and Sumo Logic, I monitor and optimize application performance for an exceptional user experience.",
        "⚡ Staying updated with cutting-edge technologies like Hibernate, Java, JavaScript, and Redis, I deliver high-quality backend solutions with streamlined project management.",
      ],
      softwareSkills: [
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Kafka",
          fontAwesomeClassname: "logos:kafka-icon",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "devicon:mongodb-wordmark",
          style: {
            color: "#F7DF1E",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "logos:redis",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Hibernate",
          fontAwesomeClassname: "logos:hibernate",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Prometheus",
          fontAwesomeClassname: "logos:prometheus",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Blockchain Developer",
      fileName: "BlockchainImg",
      skills: [
        "⚡ With expertise in Solidity, I can effectively develop secure and efficient smart contracts on the Ethereum blockchain, ensuring reliable decentralized application functionality.",
        "⚡ I possess the ability to seamlessly integrate smart contracts with popular frontend frameworks like React, Next.js, and others, enabling smooth user interactions and experiences.",
        "⚡ I am well-versed in utilizing essential tools such as Ganache, Metamask, Web3, and Infura, enabling efficient development and interaction with the Ethereum ecosystem.",
        "⚡ I have the necessary knowledge and skills to successfully deploy blockchain applications, connecting them to the Ethereum network using tools like Infura for convenient access.",
      ],
      softwareSkills: [
        {
          skillName: "Solidity",
          fontAwesomeClassname: "skill-icons:solidity",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Ethereum",
          fontAwesomeClassname: "logos:ethereum",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Ganache",
          fontAwesomeClassname: "logos:ganache-icon",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Web3",
          fontAwesomeClassname: "logos:web3js",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Metamask",
          fontAwesomeClassname: "logos:metamask-icon",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
  ],
};

// Education Page
const educationDescription = {
  title: "Education",
  subtitle: "Basic Qualification and Certifcations",
  description:
    "I pursued my B.Tech. in Computer Science and Engineering at the National Institute of Technology Srinagar from 2019 to 2023. I achieved a CGPA of 9.17 out of 10. Prior to that, I completed my Class 12th education at The Pillars Public School, Gorakhpur, with a percentage of 90. Additionally, I completed my Class 10th education at ST. Thomas Inter College, Khalilabad, with a percentage of 93.",
};

const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Leetcode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "https://bit.ly/3TAmPK1",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://bit.ly/3TZQsV6",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://bit.ly/3fajDWT",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "National Institute of Technology Srinagar",
      subtitle: "B.Tech. in Computer Science and Engineering",
      logo_path: "nitsgr_logo.png",
      alt_name: "NIT SGR",
      duration: "2019 - Present",
      descriptions: [
        "⚡ Studied all the necessary Computer Science Subjects under BTech Program which included Data Structures and Algorithms, Operating Systems, Computer Networks, Database Management System, Network Security, Cloud Computing, Compiler Design, Discrete Mathematics",
        "⚡ CGPA : 9.17 / 10",
      ],
      website_link: "https://nitsri.ac.in/",
    },
    {
      title: "The Pillars Public School , Gorakhpur",
      subtitle: "Intermediate",
      logo_path: "tpps_logo.png",
      alt_name: "Pillars School",
      duration: "2018",
      descriptions: [
        "⚡ Studied Mathematics, Physics, Chemistry and English as mendatory subjects for course and Computer Science(DSA in C++) as optional subject",
        "⚡ Percentage : 90",
        "⚡ Won 1st prize in Inter-House Futsal Competition",
      ],
      website_link: "https://www.thepillarspublicschool.in/",
    },
    {
      title: "ST. Thomas Inter College , Khalilabad",
      subtitle: "High School",
      logo_path: "sts_logo.png",
      alt_name: "ST Thomas School",
      duration: "2016",
      descriptions: [
        "⚡ Studied Computer Science(Java) as part of the course",
        "⚡ Percentage : 93",
        "⚡ Won 1st prize in Inter-School Skit Competition",
        "⚡ Won 1st prize for singing in Inter-House Cultural Competition",
      ],
      website_link: "http://www.stskhalilabad.com/",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "Data Structures and Algorithms in C++",
    //   subtitle: "- Coding Ninjas",
    //   logo_path: "codingninjas_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1_fP92nW-PO2mmFt5Y9YblQ-YSNuBHJvc/view?usp=sharing",
    //   alt_name: "Coding Ninjas",
    //   color_code: "#02569B",
    // },
    {
      title: "Full Stack Web Development",
      subtitle: "- Udemy (Dr. Angela Yu)",
      logo_path: "udemy_logo.svg",
      certificate_link:
        "https://drive.google.com/file/d/1BDoQm7Myan_Rn0guB8JnT_aO07SdgjiA/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#FB813E",
    },
    {
      title: "Quadcopter Workshop",
      subtitle: "- IIT Bhubaneswar",
      logo_path: "quadcopter_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/0B9Vw5vlYuGlCeUNjOVg3VENrenJPVGhDYTk2aUNvRWNyMFBv/view?usp=sharing&resourcekey=0-L8HjUUsgLM9uUgE54yUITQ",
      alt_name: "Quadcopter",
      color_code: "#3BA4E9",
    },
    {
      title: "Innovation and Entreprenuership",
      subtitle: "- NIT Srinagar",
      logo_path: "nitcerti_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/13ifGWy3Pabe-B5ostdN55JN93gTvCiiQ/view?usp=sharing",
      alt_name: "NIT Srinagar",
      color_code: "#8C151599",
    },

    {
      title: "Application of ML and Deep Learning in Engineering Problems",
      subtitle: "-NIT Srinagar",
      logo_path: "nitcerti_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1h5U7bmJPkWa0WK8Z56PxKo3d-kusLCvI/view?usp=sharing",
      alt_name: "NIT Srinagar",
      color_code: "#8C151599",
    },
  ],
};
const projectTechStacks = {
  html: {
    name: "HTML",
    skillName: "HTML",
    iconifyClass: "logos:html-5",
  },
  css: {
    name: "CSS",
    skillName: "CSS",
    iconifyClass: "logos:css-3",
  },
  javascript: {
    name: "JavaScript",
    skillName: "JavaScript",
    iconifyClass: "logos:javascript",
  },
  python: {
    name: "Python",
    skillName: "Python",
    iconifyClass: "logos:python",
  },
  react: {
    name: "React",
    skillName: "React",
    iconifyClass: "skill-icons:react-dark",
  },
  redux: {
    name: "Redux",
    skillName: "Redux",
    iconifyClass: "skill-icons:redux",
  },
  nodejs: {
    name: "NodeJS",
    skillName: "NodeJS",
    iconifyClass: "logos:nodejs",
  },
  expressjs: {
    name: "ExpressJS",
    skillName: "ExpressJS",
    iconifyClass: "skill-icons:expressjs-dark",
  },
  mongodb: {
    name: "MongoDB",
    skillName: "MongoDB",
    iconifyClass: "devicon:mongodb-wordmark",
  },
  aws: {
    name: "AWS",
    skillName: "AWS",
    iconifyClass: "skill-icons:aws-dark",
  },
  nextjs: {
    name: "NextJS",
    skillName: "NextJS",
    iconifyClass: "logos:nextjs-icon",
  },
  solidity: {
    name: "Solidity",
    skillName: "Solidity",
    iconifyClass: "skill-icons:solidity",
  },
  ganache: {
    name: "Ganache",
    skillName: "Ganache",
    iconifyClass: "logos:ganache-icon",
  },
  web3: {
    name: "Web3",
    skillName: "Web3",
    iconifyClass: "logos:web3js",
  },
  metamask: {
    name: "Metamask",
    skillName: "Metamask",
    iconifyClass: "logos:metamask-icon",
  },
  ethereum: {
    name: "Ethereum",
    skillName: "Ethereum",
    iconifyClass: "logos:ethereum",
  },
  machineLearning: {
    name: "Machine Learning",
    skillName: "Machine Learning",
    iconifyClass: "carbon:machine-learning-model",
  },
  ejs: {
    name: "Ejs",
    skillName: "Ejs",
    iconifyClass: "vscode-icons:file-type-ejs",
  },
};

const projects = [
  {
    name: "Automated Placement Portal",
    preview: "AutomatedPlacementPortal.png",
    links: {
      github: "https://github.com/tm2k23/Automated-Placement-Portal-NITSGR",
      live: null,
      youtube: null,
    },
    description:
      "An automated web application created to streamline the placement process at NIT Srinagar, it aims to decrease the workload of Recruitment Coordinators and provide a dashboard for the TNP Head to analyze college placement data and student performance, with the goal of enhancing future placements. Email registration is verified through OTP and students' academic data is retrieved from the college database, eliminating the possibility of fake data being provided in the application such as CGPAs and Backlogs. Company recruiters will fill out a JNF form, which will be verified by an admin, and students will receive email notifications to apply. CRCs can export a list of applicants in an XLS sheet, and backend eligibility restrictions are applied as per the college's placement policy.",
    techStacks: ["react", "redux", "nodejs", "expressjs", "mongodb"],
  },
  {
    name: "Clothify - Myntra Fashion",
    preview: "Myntra.png",
    links: {
      github: "https://github.com/tm2k23/myntra",
      live: "https://myntra-clone-tejas.netlify.app/",
      youtube: "https://youtu.be/S1mtOA2tJV4",
    },
    description:
      "This is an e-commerce web-app with variety of features, including Filters, Sort, Bag, Wishlist, View Similar Products, and Search Products. Users can easily filter products by Gender, Discount Range, Color, and Price, and sort items by Price, Discount, Ratings, and Review Counts to help them find their desired product. The app is also designed to be responsive on all devices, ensuring a smooth user experience even on small screens.",
    techStacks: ["html", "css", "javascript", "react", "redux"],
  },
  {
    name: "Remote Health Monitoring",
    preview: "RemoteHealthMonitoringProtected.png",
    links: {
      github: null,
      live: null,
      youtube: null,
    },
    description:
      "Patent-restricted project showcasing problem-solving skills, diverse technologies, and achieving successful outcomes. Further insights available upon request.",
    techStacks: [
      "nextjs",
      "solidity",
      "python",
      "metamask",
      "web3",
      "machineLearning",
    ],
  },
  {
    name: "Milko-Chain",
    preview: "MilkoChainProtected.jpeg",
    links: {
      github: null,
      live: null,
      youtube: null,
    },
    description:
      "Patent-restricted project showcasing problem-solving skills, diverse technologies, and achieving successful outcomes. Further insights available upon request.",
    techStacks: [
      "nextjs",
      "solidity",
      "ganache",
      "metamask",
      "web3",
      "ethereum",
    ],
  },
  {
    name: "Codeial",
    preview: "Codeial.png",
    links: {
      github: "https://github.com/tm2k23/codeial",
      live: null,
      youtube: null,
    },
    description:
      "A social media platform which includes functionality such as chat, posting, commenting, replying, liking, adding or removing friends, uploading a profile picture, and logging in or signing up. Users can log in using Google with the help of PassportJS for easy access. Email notifications for likes, comments, and posts are sent to users using nodemailer and a job scheduler called Kue. Users can also upload a profile picture and chat with friends using multer and socket.io.",
    techStacks: ["html", "css", "javascript", "nodejs", "expressjs", "mongodb"],
  },
  {
    name: "Ipod.JS",
    preview: "Ipod.png",
    links: {
      github: "https://github.com/tm2k23/ipod",
      live: "https://react-ipod.netlify.app/",
      youtube: "https://youtu.be/JsP6UsRSabU",
    },
    description:
      "The App functions like an iPod, allowing users to play songs, games, etc. The interface incorporates rotating gestures, controlled by a coordinate system, to provide an intuitive and interactive user experience. ",
    techStacks: ["html", "css", "javascript", "react"],
  },
  {
    name: "Echo-Heal",
    preview: "EchoHeal.png",
    links: {
      github: "https://github.com/tm2k23/Echo-Heal",
      live: "https://echo-heal.netlify.app/",
      youtube: null,
    },
    description:
      "It is a fully responsive, feature-rich, and visually stunning frontend of a music player.",
    techStacks: ["html", "css"],
  },
  {
    name: "TaskHub - Task Manager",
    preview: "ToDo.png",
    links: {
      github: "https://github.com/tm2k23/ToDoApp",
      live: null,
      youtube: null,
    },
    description:
      "The web app allows users to keep track of tasks they need to complete. It has features such as the ability to add a task description, assign a category, set a due date, add or delete tasks, and strike through completed tasks.",
    techStacks: ["html", "css", "javascript", "nodejs", "expressjs", "mongodb"],
  },
  {
    name: "CineSearch",
    preview: "MovieApp.png",
    links: {
      github: "https://github.com/tm2k23/cine-search",
      live: null,
      youtube: null,
    },
    description:
      "Cine-Search allows users to browse vast collection of movies. Using the OMDB API, movie data including ratings is fetched and displayed to provide users with comprehensive information about each movie. Additionally, Cine-Search features a search box that enables users to find specific movies of interest. To enhance the user experience, the app incorporates a favorite functionality, allowing users to mark movies as favorites and easily access them later. Cine-Search provides an immersive movie search experience for movie enthusiasts.",
    techStacks: ["html", "css", "javascript", "react", "redux"],
  },
  {
    name: "Ping Pong Game",
    preview: "PingPong.png",
    links: {
      github: "https://github.com/tm2k23/ping-pong",
      live: "https://tm2k23.github.io/ping-pong/",
      youtube: null,
    },
    description:
      "The game features two rods and one ball, where the player's objective is to hit the ball back and forth between the rods without letting it pass. To start the game, the user simply needs to press the Enter key. The rods can be controlled by pressing the 'a' key to move left and the 'd' key to move right, allowing the player to position the rods strategically to hit the ball.",
    techStacks: ["html", "css", "javascript"],
  },
];

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I excelled in impactful internships at Upstox and ACMENT, showcasing expertise in backend, API design, and frontend. Spearheaded pivotal projects optimizing FNO and Margin Pledge with efficient cron jobs and REST APIs. Mentorship at Coding Ninjas sharpened problem-solving.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Software Development Engineer",
          company: "Centre for Development of Telematics (C-DOT)",
          company_url: "https://www.cdot.in/",
          logo_path: "cdotindia_logo.jpeg",
          duration: "Aug 2023 - PRESENT",
          location: "New Delhi, India",
          descriptions: [
            "I spearheaded the development of Jan Suraksha, a healthcare platform that positively influenced over 300,000 users by providing extensive medical consultancy services. Simultaneously, I managed Doctor-Patient interfaces and records, enabling over 1 million individuals to access secure virtual consultations and medical data. Additionally, I engineered Mission Critical Services (MCX) solutions compliant with 3GPP standards, establishing Location Information and Group Management Servers for precise and efficient data handling",
          ],
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Development Engineer Intern",
          company: "Upstox",
          company_url: "https://upstox.com/",
          logo_path: "upstox.gif",
          duration: "Jan 2023 - Jul 2023 ( 7 Months )",
          location: "Bangalore, India",
          descriptions: [
            "I was instrumental in optimizing backend functions for FNO and Margin Pledge services, achieving a 10% efficiency boost through cron job enhancements. Designed REST APIs for FNO accessibility, resolved issues arising from null remarks in pledge requests, and developed a Kafka producer for immediate collateral allocation. These initiatives bolstered system efficiency and greatly improved user experience.",
          ],
          color: "#ee3c26",
        },
        {
          title: "Apprenticeship",
          company: "Amazon ML Summer School",
          company_url: "https://amazonmlsummerschoolindia.splashthat.com/",
          logo_path: "amazon.gif",
          duration: "Jun 2022 - Aug 2022 ( 3 Months )",
          location: "Work From Home",
          descriptions: [
            "It was an integrated learning program on key Machine Learning (ML) topics including Supervised Learning, Deep Neural Networks, Dimensionality Reduction, Unsupervised Learning, Probabilistic Graphical Models, Sequential Learning, Causal Inference and Reinforcement Learning.",
          ],
          color: "#0071C5",
        },
        {
          title: "SDE Intern",
          company: "ACMENT Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/acment/",
          logo_path: "acment_logo.jpg",
          duration: "Oct 2021 - Feb 2022 ( 5 Months )",
          location: "Varansi, Uttar Pradesh",
          descriptions: [
            "During my tenure, I had the opportunity to contribute to the development of a cutting-edge Payment Service, seamlessly integrated with Razorpay, leveraging Webhooks for efficient payment verification. This transformative approach led to a remarkable reduction of over 20% in the verification failure rate, surpassing the limitations of traditional browser-based verification calls. Additionally, I played a pivotal role in the creation of a user-focused Classroom Platform, providing a captivating and immersive learning experience. By implementing key features such as mentor selection, scheduling mentor sessions, and precise tracking of watched chapters, I ensured a personalized and engaging learning journey for our users.",
          ],
          color: "#0071C5",
        },
        {
          title: "Data Structures and Algorithm Teaching Assistant",
          company: "Coding Ninjas",
          company_url: "https://www.codingninjas.com/",
          logo_path: "codingninjas_logo.jpg",
          duration: "Jun 2021 - Sep 2021 ( 4 Months )",
          location: "Work From Home",
          descriptions: [
            "Assumed responsibility for troubleshooting and debugging student code, enhancing code quality and conducting doubt-clearing sessions, which honed my logical and problem-solving abilities. Achieved an average rating of 4.95 out of 5 from students.Acted as a mentor to 958 students, successfully resolving over 2000 doubts and queries.",
          ],
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Campus Hero",
          company: "GirlScript Foundation",
          company_url: "https://www.girlscript.tech/",
          logo_path: "girlscript_logo.png",
          duration: "June 2021 - Jan 2022",
          location: "Remote",
          descriptions: [
            "As a Campus Hero at GirlScript Foundation, I promoted inclusivity in technology by organizing coding workshops, competitions, and mentorship programs for students. By fostering a supportive community, I empowered individuals of all genders to excel in coding and contribute to the tech industry.",
          ],
          color: "#0071C5",
        },
        {
          title: "Campus Ambassador",
          company: "Coding Ninjas",
          company_url: "https://www.codingninjas.com/",
          logo_path: "codingninjas_logo.jpg",
          duration: "Oct 2020 - May 2021",
          location: "Remote",
          descriptions: [
            "As a Campus Ambassador at Coding Ninjas, I represented a leading coding education platform, promoting courses, workshops, and coding competitions on my campus. I organized coding events, collaborated with student clubs, and hosted interactive sessions on programming languages and algorithms. I actively engaged with the campus community through social media, creating and sharing informative content. I played a role in the recruitment process, conducting information sessions and guiding students towards Coding Ninjas' programs. This experience developed my communication, event management, and leadership skills while fostering a passion for coding education. I am proud of the impact I made and look forward to continuing my journey as a coding advocate.",
          ],
          color: "#0071C5",
        },
        {
          title: "Intershala Student Partner",
          company: "Internshala",
          company_url: "https://internshala.com/",
          logo_path: "internshala_logo.png",
          duration: "Feb 2020 - Oct 2020",
          location: "Remote",
          descriptions: [
            "As an Internshala Student Partner, I promoted internship and training programs, organized campus campaigns, and facilitated recruitment drives. Through social media, I shared career guidance and connected students with internship opportunities. This experience enhanced my communication skills and deepened my understanding of the importance of internships in shaping careers.",
          ],
          color: "#0071C5",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I specialize in creating captivating web applications and deploying them. With a strong focus on delivering seamless user experiences, I have successfully developed numerous innovative solutions. Additionally, I am actively involved in blockchain projects, exploring its potential for transformative applications.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "tejas.png",
    description:
      "Seeking a talented developer to power your company's growth? Look no further! I offer expertise in Spring Boot, Data Structures, Algorithms, Node.js, and React. Let's collaborate to deliver exceptional results. Connect today to explore how my skills can drive your company's success.",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
  //   link: "https://ashutoshhathidara.wordpress.com",
  //   avatar_image_path: "blogs_image.svg",
  // },
  addressSection: {
    title: "Address",
    subtitle:
      "Room No - 07, Jhelum Hostel, National Institute of Technology Srinagar, Hazaratbal, Jammu & Kashmir - 190006",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/DqPTYGJLauXJrAnF8",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 9455786258 or +91 7992180711",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
  projects,
  projectTechStacks,
  educationDescription,
};
