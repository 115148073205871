import React, { Component } from "react";

export default class BackendImg extends Component {
    render() {
        const theme = this.props.theme;
        return (
            <img
                style={{
                    height: 'auto',
                    marginTop: '-20px',
                }}
                src = {require(`../../assests/images/backend.svg`)}
            />
        )
    }
}